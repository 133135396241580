import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
  getColumnSelectSearchProps,
} from '../../components';
import {
  checkValidData,
  checkValidDiscountType,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute as membershipRoute } from '../../routes/Membership';
import {
  getPlanDurations,
  getPurchaseBy,
  getStatusData,
  typesOfBenefit,
} from '../index.service';
import {
  phoneNumberFormate,
  readMoreTextShow,
  showDateInBrowser,
} from '../../utils';
import { DATE_TH_MONTH_YEAR } from '../../config';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';

export const memberShipBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.memberShip.heading'),
    },
  ];
};

export const memberShipHistoryBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.memberShip.membershipHisotryHeading'),
    },
  ];
};

export const addMembershipPlanBreadcrumb = ({ text }) => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: membershipRoute.MEMBERSHIP.path,
      name: i18next.t('text.memberShip.heading'),
    },
    {
      path: '#',
      name:
        text === 'add'
          ? i18next.t('text.memberShip.addMembershipPlan')
          : i18next.t('text.memberShip.editMembershipPlan'),
    },
  ];
};

const getPaymentMethodData = () => {
  return [
    {
      name: 'Cash',
      id: 'cash',
    },
    {
      name: 'Card',
      id: 'card',
    },
    {
      name: 'Check',
      id: 'check',
    },
    {
      name: 'Financing',
      id: 'financing',
    },
  ];
};

const getPaymentStatusData = () => {
  return [
    {
      name: 'Settled',
      id: 'settled',
    },
    {
      name: 'Unsettled',
      id: 'unsettled',
    },
  ];
};

const getMemberShipTypesFilter = () =>{
  return [
    {
      name: 'Purchase',
      id: 'purchase',
    },
    {
      name: 'Renew',
      id: 'renew',
    },
  ];
}

// use temporary keys
const monthlyCostFields = {
  fromOperatorField: 'monthlyCostFirstOperator',
  fromOperatorAmountField: 'monthlyCostFirstAmount',
  conditionalField: 'monthlyCostConditional',
  toOperatorField: 'monthlyCostSecondOperator',
  toOperatorAmountField: 'monthlyCostSecondAmount',
};
const yearlyCostFields = {
  fromOperatorField: 'yearlyCostFirstOperator',
  fromOperatorAmountField: 'yearlyCostFirstAmount',
  conditionalField: 'yearlyCostConditional',
  toOperatorField: 'yearlyCostSecondOperator',
  toOperatorAmountField: 'yearlyCostSecondAmount',
};
const planAmountFields = {
  fromOperatorField: 'planAmountFirstOperator',
  fromOperatorAmountField: 'planAmountFirstAmount',
  conditionalField: 'planAmountConditional',
  toOperatorField: 'planAmountSecondOperator',
  toOperatorAmountField: 'planAmountSecondAmount',
};
export const memberShipColumn = ({
  onSort,
  page,
  onFilterChange,
  filter,
  sizePerPage,
  Action,
  Toggle,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'title',
      title: i18next.t('text.common.title'),
      filterDataIndex: ['title'],
      sorter: true,
      onSort: (field, order) => onSort('name', order),
      render: (cell, row) => checkValidData(row?.name),
      ...getInputSearchFilter({
        dataIndex: 'title',
        placeholder: i18next.t('text.common.titlePlaceholder'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'monthlyCost',
      filterDataIndex: ['monthlyCostConditional'],
      title: i18next.t('text.memberShip.monthlyCost'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('monthlyPrice', order),
      render: (cell, row) => checkValidPrice(row?.monthlyPrice),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: monthlyCostFields,
        filter,
      }),
      // ...getColumnSelectAmountProps('monthly cost'),
    },
    {
      dataIndex: 'yearlyCost',
      filterDataIndex: ['yearlyCostConditional'],
      title: i18next.t('text.memberShip.yearlyOneTime'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('yearlyPrice', order),
      render: (cell, row) => checkValidPrice(row?.yearlyPrice),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: yearlyCostFields,
        filter,
      }),
      // ...getColumnSelectAmountProps('yearly one time cost'),
    },
    {
      dataIndex: 'planType',
      filterDataIndex: ['planType'],
      title: i18next.t('text.memberShip.planType'),
      // headerClasses: 'sorting',
      // sorter: true,
      // onSort: (field, order) => onSort('typeOfBenefit', order),
      render: (cell, row) =>
        row?.typeOfBenefit === 'combo'
          ? i18next.t('text.memberShip.comboPlankey')
          : row?.typeOfBenefit === 'service_category'
          ? i18next.t('text.memberShip.serviceCategoryPlankey')
          : row?.typeOfBenefit === 'line_item'
          ? i18next.t('text.memberShip.lineItemPlankey')
          : row?.typeOfBenefit === 'quote'
          ? i18next.t('text.memberShip.quotePlankey')
          : i18next.t('text.common.noItem'),
      // ...getColumnSelectSearchProps({
      //   dataIndex: 'planType',
      //   onFilterChange,
      //   filter,
      //   mode: false,
      //   list: typesOfBenefit(),
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    {
      dataIndex: 'subCategoryDiscount',
      filterDataIndex: ['subCategoryDiscount'],
      title: i18next.t('text.memberShip.subCategoryDiscount'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        row?.typeOfBenefit !== 'quote' && row?.savingType && row?.savingAmount
          ? checkValidDiscountType(row?.savingType, row?.savingAmount)
          : i18next.t('text.common.noItem'),
      // ...getInputSearchFilter({
      //   dataIndex: 'additionalDiscount',
      //   placeholder: i18next.t('text.memberShip.discountPlaceholder'),
      //   onFilterChange,
      //   filter,
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    {
      dataIndex: 'quoteDiscount',
      filterDataIndex: ['quoteDiscount'],
      title: i18next.t('text.memberShip.quoteDiscount'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        row.typeOfBenefit === 'quote'
          ? row?.savingType && row?.savingAmount
            ? checkValidDiscountType(row?.savingType, row?.savingAmount)
            : i18next.t('text.common.noItem')
          : row?.quoteSavingType && row?.quoteSavingAmount
          ? checkValidDiscountType(row?.quoteSavingType, row?.quoteSavingAmount)
          : i18next.t('text.common.noItem'),
      // ...getInputSearchFilter({
      //   dataIndex: 'additionalDiscount',
      //   placeholder: i18next.t('text.memberShip.discountPlaceholder'),
      //   onFilterChange,
      //   filter,
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    {
      dataIndex: 'lineItemDiscount',
      filterDataIndex: ['lineItemDiscount'],
      title: i18next.t('text.memberShip.lineItemDiscount'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        row?.lineItemSavingType && row?.lineItemSavingAmount
          ? checkValidDiscountType(
              row?.lineItemSavingType,
              row?.lineItemSavingAmount
            )
          : i18next.t('text.common.noItem'),
      // ...getInputSearchFilter({
      //   dataIndex: 'additionalDiscount',
      //   placeholder: i18next.t('text.memberShip.discountPlaceholder'),
      //   onFilterChange,
      //   filter,
      // }),
      // ...getColumnSearchProps('additional discount'),
    },
    // {
    //   dataIndex: 'lengthOfMembership',
    //   title: i18next.t('text.memberShip.lengthOfMembership'),
    //   headerClasses: 'sorting',
    //   sorter: true,
    //   render: (cell, row) => checkValidData(cell),
    //   ...getInputSearchFilter({
    //     dataIndex: 'search',
    //     placeholder: i18next.t('text.memberShip.lengthOfMembershipPlaceholder'),
    //   }),
    //   // ...getColumnSearchProps('length of membership'),
    // },
    {
      dataIndex: 'displayOrder',
      title: i18next.t('text.memberShip.displayOrder'),
      render: (cell, row) => checkValidData(cell),
    },
    {
      dataIndex: 'description',
      title: i18next.t('text.common.description'),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.common.description'),
          20
        ),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        name: 'status',
        onFilterChange,
        filter,
        mode: false,
        list: getStatusData(),
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => <Action row={row} cell={cell} />,
    },
  ];
};

export const memberShipHistoryColumn = ({
  page,
  onFilterChange,
  filter,
  sizePerPage,
  handleAlert,
  isShowSettle
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'bookingNo',
      title: i18next.t('text.memberShip.jobNo'),
      filterDataIndex: ['jobNumber'],
      render: (cell, row) => checkValidData(row?.CustomerMembership?.Booking?.bookingNo) || '-',
      ...getInputSearchFilter({
        dataIndex: 'jobNumber',
        placeholder: 'job number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customerName',
      title: i18next.t('text.memberShip.customerName'),
      sorter: false,
      render: (cell, row) =>
        checkValidData(
          `${ 
            row?.CustomerMembership?.customer?.firstName ?
             row?.CustomerMembership?.customer?.firstName : 
             ''
            }
             ${row?.CustomerMembership?.customer?.lastName ? 
              row?.CustomerMembership?.customer?.lastName : 
              ''
            }`
        ),
      ...getInputSearchFilter({
        dataIndex: 'customerName',
        placeholder: i18next.t('text.common.customerNameSearchPlaceHolder'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'email',
      title: i18next.t('text.common.email'),
      filterDataIndex: ['customerEmail'],
      render: (cell, row) =>
        checkValidData(row?.CustomerMembership?.customer?.email),
      ...getInputSearchFilter({
        dataIndex: 'customerEmail',
        placeholder: 'email',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'phoneNumber',
      title: i18next.t('text.common.phone'),
      exportFormatter: (cell, row) => {
        const formatNumber = phoneNumberFormate(
          row?.CustomerMembership?.customer?.phoneNumber,
          row?.CustomerMembership?.customer?.countryPhoneCode
        );
        return formatNumber ?? '-';
      },
      render: (cell, row) => {
        const formatNumber = phoneNumberFormate(
          row?.CustomerMembership?.customer?.phoneNumber,
          row?.CustomerMembership?.customer?.countryPhoneCode
        );
        return formatNumber ?? '-';
      },
      ...getInputSearchFilter({
        dataIndex: 'phoneNumber',
        placeholder: 'mobile',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'purchasedBy',
      title: i18next.t('text.memberShip.purchasedBy'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) =>
        getPurchaseBy()?.find(
          (item) =>
            item.id === row?.CustomerMembership?.planPurchaseByUser?.Role?.name
        )?.name ?? 'Sub Admin',
      ...getColumnSelectSearchProps({
        dataIndex: 'purchasedBy',
        name: i18next.t('text.common.purchasedBySearchPlaceHolder'),
        onFilterChange,
        filter,
        mode: false,
        list: getPurchaseBy(),
      }),
    },
    {
      dataIndex: 'startDateTime',
      filterDataIndex: ['purchaseFrom', 'purchaseTo'],
      title: i18next.t('text.memberShip.purchasedDate'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (cell, row) =>
        showDateInBrowser(cell, DATE_TH_MONTH_YEAR),
      render: (cell, row) => showDateInBrowser(cell, DATE_TH_MONTH_YEAR),
      ...getFromToDateFilter({
        dataStartIndex: 'purchaseFrom',
        dataEndIndex: 'purchaseTo',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'type',
      filterDataIndex: ['membershipType'],
      title: i18next.t('text.memberShip.membershipType'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => cell?.replace(/^./, cell[0]?.toUpperCase()),
      ...getColumnSelectSearchProps({
        dataIndex: 'membershipType',
        name: 'by membership type',
        onFilterChange,
        filter,
        mode: false,
        list: getMemberShipTypesFilter(),
      }),
    },
    {
      dataIndex: 'PlanType',
      filterDataIndex: ['planType'],
      title: i18next.t('text.memberShip.planType'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) =>
        typesOfBenefit()?.find(
          (item) =>
            item.value ===
            row?.CustomerMembership?.MembershipPlan?.typeOfBenefit
        )?.name ?? i18next.t('text.common.noItem'),
      ...getColumnSelectSearchProps({
        dataIndex: 'planType',
        name: i18next.t('text.common.planTypeSearchPlaceHolder'),
        onFilterChange,
        filter,
        mode: false,
        list: typesOfBenefit(),
      }),
    },
    {
      dataIndex: 'durationType',
      title: i18next.t('text.memberShip.planDuration'),
      render: (cell, row) =>
        getPlanDurations()?.find(
          (item) => item.id === row?.CustomerMembership?.durationType
        )?.name ?? i18next.t('text.common.noItem'),
        ...getColumnSelectSearchProps({
          dataIndex: 'durationType',
          name: 'plan duration',
          onFilterChange,
          filter,
          mode: false,
          list: getPlanDurations(),
        }),
    },
    {
      dataIndex: 'planAmount',
      title: i18next.t('text.memberShip.planAmount'),
      headerClasses: 'sorting',
      sorter: false,
      filterDataIndex: ["planAmountConditional"],
      render: (cell, row) =>
        checkValidPrice(
          row?.CustomerMembership?.durationType === 'monthly'
            ? row?.CustomerMembership?.MembershipPlan?.monthlyPrice
            : row?.CustomerMembership?.MembershipPlan?.yearlyPrice
        ),
      ...getPriceFilter({
        dataIndex: 'planAmount',
        onFilterChange,
        fields: planAmountFields,
        filter,
      }),
    },
    {
      dataIndex: 'paymentMethod',
      title: i18next.t('text.memberShip.paymentMethod'),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.CustomerMembership?.paymentMethod),
          i18next.t('text.common.description'),
          20
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'paymentMethod',
        onFilterChange,
        name: 'payment method',
        list: [],
        mode: 'multiple',
        listService: getPaymentMethodData,
        filter,
      }),
    },
    {
      dataIndex: 'endDateTime',
      title: i18next.t('text.memberShip.validityDate'),
      filterDataIndex: ['validatedFrom', 'validatedTo'],
      sorter: false,
      exportFormatter: (cell, row) =>
        showDateInBrowser(cell, DATE_TH_MONTH_YEAR),
      render: (cell, row) => showDateInBrowser(cell, DATE_TH_MONTH_YEAR),
      ...getFromToDateFilter({
        dataStartIndex: 'validatedFrom',
        dataEndIndex: 'validatedTo',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'settle',
      title: i18next.t('text.memberShip.paymentStatus'),
      filterDataIndex: ['paymentStatus'],
      render: (cell, row) => (cell === 1 ? 'Settled' : 'Unsettled'),
      ...getMultiSelectSearchFilter({
        dataIndex: 'paymentStatus',
        onFilterChange,
        name: 'payment status',
        list: [],
        mode: false,
        listService: getPaymentStatusData,
        filter,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: '',
      render: (cell, row) =>
        row?.settle === 0 && isShowSettle ? (
          <button className="btn btn-primary" onClick={() => handleAlert(row)}>
            Settle
          </button>
        ) : (
          '-'
        ),
    },
  ];
};
