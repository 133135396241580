import { useEffect } from "react";
import { detectOSService } from "../../services/index.service";

export default function DetectOs() {
    const detectOsData = async () => {
        try {
            const response = await detectOSService();
            if (response) {
                window.open(response?.data?.appUrl, '_self');
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        detectOsData();
    }, []);
    return <>
        {/*  return anything here */}
    </>;
}
