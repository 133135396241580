import {
  COMMON_ENDPOINT,
  MENTAL_HEALTH_STATUS,
} from '../../apiEndpoints/index.endpoint';
import { CUSTOMER_APP_ENDPOINT } from '../../apiEndpoints/CustomerApp/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { accessRoute } from '../../routes/CustomerApp/booking';
import { accessRoute as TipsOfMonth } from '../../routes/CustomerApp/tipsOfMonth';
import { accessRoute as reviewRating } from '../../routes/CustomerApp/reviewRating';
import { accessRoute as homeRoute } from '../../routes/CustomerApp/home';
import { accessRoute as customerRoute } from '../../routes/CustomerApp/payment';
import { generatePath } from 'react-router-dom';

const imageAcceptType = { image: '.jpg, .jpeg, .png' };

export const getDistanceUnitList = () => {
  return [
    {
      id: 'KMs',
      name: 'KMs',
    },
    {
      id: 'miles',
      name: 'Miles',
    },
  ];
};
export const getBooleanYesNoList = () => {
  return [
    {
      id: '1',
      name: 'Yes ',
    },
    {
      id: '0',
      name: 'No',
    },
  ];
};
export const getStaticInvoiceData = () => {
  return {
    providerName: 'Jorge neon',
    providerEmail: 'jorge@mailinator.com',
    userName: 'Shane Watson',
    userEmail: 'shanewatson@mailinator.com',
    bookingAddress: ': Vijay Nagar, Indore, Madhya Pradesh 452010, India',
    jobId: '75833147',
    jobDate: '2023-03-13T08:19:46.000Z',
    serviceDetails: 'QA & CATEGORY',
    serviceName: 'Free Sch no quote',
    qty: '1',
    serviceCharge: '5000.00',
    lineItem: [
      {
        id: 6,
        bookingId: 44,
        bookingQuoteId: 8,
        lineItemId: null,
        qty: 1,
        total: '10.00',
        unitPrice: '10.00',
        name: " Honeywell T6 Th6320Wf2003 24V Lyric T6 Pro{' '}",
        description:
          " The T6 Pro Z-Wave Thermostat provides time-based  scheduling for 7-day, 5-1-1, and 5-2 day  programmable scheduling, as well as remote control temperature access with impressive wireless range. Now you can save on energy costs whether when you're",
        isCustomerChecked: false,
        isProviderChecked: false,
        image: '/assets/images/admin/line-items.jpg',
        createdAt: '2023-03-13T08:19:46.000Z',
        updatedAt: '2023-03-13T08:19:46.000Z',
      },
    ],
    servicePrice: '5000.00',
    subtotal: '5408.00',
    purchase: '400.00',
    discount: '80.00',
    sublabor: '500.00',
    returned: '200.00',
    commission: '0',
    total: '4700',
    notes: 'Arrived',
    promoCodeDiscount: '30.00',
    invoiceMessage: '-',
    otherNotes: '-',
    email: true,
    signature: true,
  };
};
export const getTupleStatusList = (isAll) => {
  if (isAll) {
    return [
      {
        id: 'all',
        name: 'All',
      },
      {
        id: 'active',
        name: 'Active',
      },
      {
        id: 'inactive',
        name: 'Inactive',
      },
    ];
  }
  return [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'inactive',
      name: 'Inactive',
    },
  ];
};

export const getTupleStatusAllList = () => {
  return [
    {
      id: 'All',
      name: 'All',
    },
    {
      id: '1',
      name: 'On',
    },
    {
      id: '0',
      name: 'Off',
    },
  ];
};
export const getStatusOfDiscountTypeList = () => {
  return [
    {
      id: 'paid',
      name: 'Paid',
    },
    {
      id: 'unpaid',
      name: 'UnPaid',
    },
    {
      id: 'both',
      name: 'Both',
    },
  ];
};

export const getRebateTypeList = () => {
  return [
    {
      label: 'Seer',
      value: 'seer',
      id: 'seer',
      name: 'Seer',
    },
    {
      label: 'Gas efficiency',
      value: 'gas_efficiency',
      id: 'gas_efficiency',
      name: 'Gas efficiency',
    },
    {
      label: 'Misc',
      value: 'misc',
      id: 'misc',
      name: 'Misc',
    },
  ];
};

export const getQuoteTypeList = () => {
  return [
    {
      id: 'good',
      name: 'Good',
    },
    {
      id: 'best',
      name: 'Best',
    },
    {
      id: 'better',
      name: 'Better',
    },
    {
      id: 'premium',
      name: 'Premium',
    },
  ];
};

export const getProfitType = () => {
  return [
    {
      id: 'percentage',
      name: '% (Percentage)',
    },
    {
      id: 'amount',
      name: '$ (Dollar)',
    },
  ];
};

export const getServiceTypeList = () => {
  return [
    {
      id: 'serviceType',
      name: 'Service Type',
    },
  ];
};

export const getUserTypeForAdmin = () => {
  return [
    {
      id: 'nca',
      name: 'NCA',
    },
    {
      id: 'subadmin',
      name: 'Sub Admin',
    },
  ];
};

export const postService = async (body, apiEndPoint) => {
  try {
    const payload = {
      ...apiEndPoint,
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const statusFilterData = () => {
  return [
    {
      name: 'Active',
      value: 'active',
    },
    {
      name: 'Inactive',
      value: 'inactive',
    },
  ];
};

export const uploadFile = (
  mediaFor,
  mediaType
  // data
) => {
  try {
    return COMMON_ENDPOINT.uploadImageFile(
      mediaType,
      mediaFor,
      imageAcceptType[mediaType]
    );
  } catch (err) {
    logger(err);
  }
};

export const uploadUsedFile = (
  mediaFor,
  mediaType
  // data
) => {
  try {
    return COMMON_ENDPOINT.uploadUsedImageFile(
      mediaType,
      mediaFor,
      imageAcceptType[mediaType]
    );
  } catch (err) {
    logger(err);
  }
};

export const uploadPublicFile = (
  mediaFor,
  mediaType
  // data
) => {
  try {
    return COMMON_ENDPOINT.uploadPublicImageFile(
      mediaType,
      mediaFor,
      imageAcceptType[mediaType]
    );
  } catch (err) {
    logger(err);
  }
};

export const addUserMonthData = () => {
  return [
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
    {
      name: '4',
      value: '4',
    },
    {
      name: '5',
      value: '5',
    },
    {
      name: '6',
      value: '6',
    },
    {
      name: '7',
      value: '7',
    },
    {
      name: '8',
      value: '8',
    },
    {
      name: '9',
      value: '9',
    },
    {
      name: '10',
      value: '10',
    },
    {
      name: '11',
      value: '11',
    },
    {
      name: '12',
      value: '12',
    },
  ];
};

export const addUserMembershipData = () => {
  return [
    {
      name: 'Paid',
      value: 'paid',
    },
    {
      name: 'Unpaid',
      value: 'unpaid',
    },
  ];
};
export const genderList = () => {
  return [
    {
      label: 'Male',
      value: 'male',
    },
    {
      label: 'Female',
      value: 'female',
    },
  ];
};

export const feeList = () => {
  return [
    {
      label: 'Percentage',
      value: 'percentage',
    },
    {
      label: 'Hourly (In Flat USD)',
      value: 'hourly',
    },
  ];
};

export const marketingList = () => {
  return [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ];
};

export const googleAdList = () => {
  return [
    {
      id: '1',
      name: ' Good Guys Heating and Air Conditioning of Lafayette(1897973164) ',
    },
    {
      id: '2',
      name: ' Tekhne of Fort Worth, Texas(3134076415) ',
    },
    {
      id: '3',
      name: ' Good Guys Heating & Air Conditioning(3257228609) ',
    },
    {
      id: '4',
      name: ' Good Guys Heating & Air Conditioning(3404387068) ',
    },
  ];
};

export const googleCampaignList = () => {
  return [
    {
      id: '1',
      name: 'google Campaign One',
    },
    {
      id: '2',
      name: 'google Campaign Two',
    },
    {
      id: '3',
      name: 'google Campaign Three',
    },
  ];
};

export const getGeneralSetting = async ({
  queryParams = { type: 'installation_setting' },
}) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT.getSetting(),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const sendEmail = async (id, queryParams) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT.sendEmail(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const changePassword = async ({ body }) => {
  try {
    const payload = {
      ...CUSTOMER_APP_ENDPOINT.changePassword,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getConditionOperatorText = (t) => {
  return [
    { id: 'eq', name: t('text.common.equalTo') },
    { id: 'gte', name: t('text.common.greaterEqualTo') },
    { id: 'lte', name: t('text.common.lessEqualTo') },
  ];
};

export const getAndOrText = (t) => {
  return [
    { id: 'and', name: t('text.common.and') },
    { id: 'or', name: t('text.common.or') },
  ];
};

export const booleanYesNoList = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Yes',
      id: 'true',
    },
    {
      name: 'No',
      id: 'false',
    },
  ];
};

export const booleanRadioYesNoList = () => {
  return [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];
};

export const visibleHideList = () => {
  return [
    {
      label: 'Visible',
      value: 'visible',
    },
    {
      label: 'Hide',
      value: 'hide',
    },
  ];
};

export const availableList = () => {
  return [
    {
      label: 'Available',
      value: 1,
    },
    {
      label: 'Not Available',
      value: 0,
    },
  ];
};

export const booleanYesNoRadioList = () => {
  return [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ];
};

export const noOfDaysList = () => {
  return [
    {
      name: '0',
      value: 0,
    },
    {
      name: '1',
      value: 1,
    },
    {
      name: '2',
      value: 2,
    },
    {
      name: '3',
      value: 3,
    },
    {
      name: '4',
      value: 4,
    },
    {
      name: '5',
      value: 5,
    },
    {
      name: '6',
      value: 6,
    },
    {
      name: '7',
      value: 7,
    },
    {
      name: '8',
      value: 8,
    },
    {
      name: '9',
      value: 9,
    },
    {
      name: '10',
      value: 10,
    },
    {
      name: '11',
      value: 11,
    },
    {
      name: '12',
      value: 12,
    },
    {
      name: '13',
      value: 13,
    },
    {
      name: '14',
      value: 14,
    },
    {
      name: '15',
      value: 15,
    },
    {
      name: '16',
      value: 16,
    },
    {
      name: '17',
      value: 17,
    },
    {
      name: '18',
      value: 18,
    },
    {
      name: '19',
      value: 19,
    },
    {
      name: '20',
      value: 20,
    },
    {
      name: '21',
      value: 21,
    },
  ];
};

export const YesNoList = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Yes',
      id: 'yes',
    },
    {
      name: 'No',
      id: 'no',
    },
  ];
};

export const activeYesNoList = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Active',
      id: 'yes',
    },
    {
      name: 'Inactive',
      id: 'no',
    },
  ];
};

export const getNotificationService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT?.getNotificationList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const discountType = () => {
  return [
    {
      label: 'Flat (in $)',
      value: 'flat',
    },
    {
      label: 'Percent (%)',
      value: 'percentage',
    },
  ];
};

export const AdjustmentSeasonData = () => {
  return [
    {
      name: '+',
      value: '+',
    },
    {
      name: '-',
      value: '-',
    },
  ];
};

export const getSign = () => {
  return [
    {
      label: '$',
      value: 'amount',
    },
    {
      label: '%',
      value: 'percentage',
    },
  ];
};
export const getAmountSign = (key) => {
  return [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: '$',
      value: key ? 'flat' : 'amount',
    },
    {
      label: '%',
      value: 'percentage',
    },
  ];
};
export const validityList = () => {
  return [
    {
      label: 'Permanent',
      value: 'permanent',
    },
    {
      label: 'Custom',
      value: 'custom',
    },
  ];
};
export const discountTypeData = () => {
  return [
    {
      name: '%',
      value: 'percentage',
    },
    {
      name: 'Flat Amount',
      value: 'amount',
    },
  ];
};
export const validityFilterData = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Permanent',
      id: 'permanent',
    },
    {
      name: 'Custom',
      id: 'custom',
    },
  ];
};
export const typesOfBenefit = () => {
  return [
    {
      name: 'Sub Category Service',
      value: 'service_category',
    },
    {
      name: 'Line Items',
      value: 'line_item',
    },
    {
      name: 'Combine Plan',
      value: 'combo',
    },
    {
      name: 'Quote',
      value: 'quote',
    },
  ];
};
export const subCategoryService = () => {
  return [
    {
      name: 'Sub Category Service',
      value: 'subCategoryService',
    },
    {
      name: 'Line Items',
      value: 'lineItem',
    },
    {
      name: 'Quotes',
      value: 'quotes',
    },
  ];
};
export const getStatusData = () => {
  return [
    // {
    //   name: 'All',
    //   id: 'all',
    // },
    {
      name: 'Active',
      id: 'active',
    },
    {
      name: 'Inactive',
      id: 'inactive',
    },
    {
      name: 'Deleted',
      id: 'deleted',
    },
  ];
};

export const getAdsStatusData = () => {
  return [
    {
      name: 'Active',
      id: 'active',
    },
    {
      name: 'Inactive',
      id: 'inactive',
    },
  ];
};

export const memberData = () => {
  return [
    {
      id: '	Member',
      name: 'Member',
    },
    {
      id: 'Non-Member',
      name: 'Non-Member',
    },
    {
      id: 'Non-Member Expired	',
      name: 'Non-Member Expired	',
    },
  ];
};

export const getIncludeDeleteStatus = (isDelete = true) => {
  if (isDelete) {
    return [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
      {
        label: 'Deleted',
        value: 'deleted',
      },
    ];
  }
  return [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];
};

export const getStatus = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Active',
      id: 'active',
    },
    {
      name: 'Inactive',
      id: 'inactive',
    },
  ];
};
export const getPlanStatusList = () => {
  return [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: '1',
      name: 'Cancelled',
    },
    {
      id: '0',
      name: 'Active',
    },
  ];
};

export const getProductStatusListFilter = () => {
  return [
    {
      name: 'Active',
      id: 'active',
    },
    {
      name: 'Inactive',
      id: 'inactive',
    },
    {
      name: 'Deleted',
      id: 'deleted',
    },
    {
      name: 'Out Of Stock',
      id: 'outOfStock',
    },
  ];
};

export const getProductStatusList = () => {
  return [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
    {
      label: 'Deleted',
      value: 'deleted',
    },
    {
      label: 'Out Of Stock',
      value: 'outOfStock',
    },
  ];
};
export const getOrderStatusFilter = () => {
  return [
    {
      name: 'Placed',
      id: 'placed',
    },
    {
      name: 'Processed',
      id: 'processed',
    },
    {
      name: 'Delivered',
      id: 'delivered',
    },
    {
      name: 'Canceled',
      id: 'canceled',
    },
    {
      name: 'Refunded',
      id: 'Refunded',
    },
  ];
};

export const getOrderStatusList = (orderStatusKey) => {
  return [
    {
      label: 'Placed',
      value: 'placed',
      disabled: orderStatusKey === 'processed' || 'delivered',
    },
    {
      label: 'Processed',
      value: 'processed',
      disabled: orderStatusKey === 'delivered',
    },
    {
      label: 'Delivered',
      value: 'delivered',
      disabled: orderStatusKey === 'placed',
    },
    {
      label: 'Canceled',
      value: 'canceled',
      disabled: false,
    },
    {
      label: 'Refunded',
      value: 'refunded',
      disabled: orderStatusKey !== 'delivered',
    },
  ];
};

export const getOrderTypeList = () => {
  return [
    {
      name: 'Self Pickup',
      value: 'self',
    },
    {
      name: 'Shipping',
      value: 'delivery',
    },
  ];
};

export const getTuplePaymentTypeList = () => {
  return [
    {
      id: 'cash',
      name: 'Cash',
    },
    {
      id: 'card',
      name: 'Card',
    },
    {
      id: 'finance',
      name: 'Financing',
    },
    {
      id: 'check',
      name: 'Check',
    },
  ];
};

export const getTuplePaymentStatusList = () => {
  return [
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'complete',
      name: 'Complete',
    },
  ];
};

export const getTuplePaymentSettleStatusList = () => {
  return [
    {
      id: 'settled',
      name: 'Settled',
    },
    {
      id: 'unsettled',
      name: 'Unsettled',
    },
  ];
};

export const getGoogleStatusList = () => {
  return [
    {
      value: 'ENABLED',
      label: 'ENABLE',
    },
    {
      value: 'PAUSED',
      label: 'PAUSED',
    },
  ];
};

const days = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export function getDay(day) {
  return days[day];
}
const daysMonday = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

export function getDayMonday(day) {
  return daysMonday[day];
}
const userName = {
  admin: 'Super Admin',
  subadmin: 'Admin',
  nca: 'NCA',
};

export const getUserTypeName = (type) => {
  // console.log('userName[type]', userName[type]);
  return userName[type] || '';
};

export const YesNoWithNumberList = () => {
  return [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: '1',
      name: 'Yes',
    },
    {
      id: '0',
      name: 'No',
    },
  ];
};

export const YesNoWithBooleanList = () => {
  return [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: true,
      name: 'Yes',
    },
    {
      id: false,
      name: 'No',
    },
  ];
};
export const getYesNoList = () => {
  return [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ];
};
export const getSubCategorySavingOption = () => {
  return [
    {
      label: 'Dollar Amount',
      value: 'flat',
    },
    {
      label: 'Percentage',
      value: 'percentage',
    },
  ];
};
export const getLocationFor = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Vehicle Type',
      id: 'serviceType',
    },
  ];
};

export const getEmojiFilterOptions = () => {
  return {
    positive: {
      key: 'positive',
      img: '/assets/images/admin/positive.png',
      query: 'Positive',
    },
    help: {
      key: 'help',
      img: '/assets/images/admin/help.png',
      query: 'Help',
    },
    struggling: {
      key: 'struggling',
      img: '/assets/images/admin/struggle.png',
      query: 'Struggle',
    },
    medical: {
      key: 'medical',
      img: '/assets/images/admin/medical.png',
      query: 'Medical',
    },
    vacation: {
      key: 'vacation',
      img: '/assets/images/admin/vacation.png',
      query: 'Vacation',
    },
    chatCount: {
      key: 'Unread User',
      img: '/assets/images/admin/vacation.png',
      query: 'Unread User',
    },
  };
};

export const providerSortOptions = () => {
  return [
    {
      value: 'all',
      label: 'All Service Provider',
    },
    {
      value: 'favorite',
      label: 'Favorite Service Provider',
    },
  ];
};

export const userTypeOptions = () => {
  return [
    {
      id: 'provider',
      name: 'Provider',
    },
    {
      id: 'customer',
      name: 'Customer',
    },
    {
      id: 'both',
      name: 'Both',
    },

  ];
};

export const imageUploadService = async ({
  mediaType,
  mediaFor,
  formData,
  contentType,
}) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT.uploadPublicImageFile(mediaType, mediaFor),
      bodyData: formData,
      contentType,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getScheduleStatusData = () => {
  return [
    {
      name: 'Pending',
      id: 'pending',
    },
    {
      name: 'Completed',
      id: 'completed',
    },
    {
      name: 'Assigned',
      id: 'assigned',
    },
    {
      name: 'Declined',
      id: 'declined ',
    },
    {
      name: 'Failed',
      id: 'failed',
    },
    {
      name: 'Accepted',
      id: 'accepted',
    },
    {
      name: 'Active',
      id: 'active',
    },
  ];
};
export const getScheduledStatusData = () => {
  return [
    {
      name: 'Completed',
      id: 'completed',
    },
    {
      name: 'Canceled',
      id: 'canceled',
    },
    {
      name: 'Pending',
      id: 'pending',
    },
    {
      name: 'Accepted',
      id: 'accepted',
    },
    {
      name: 'Expired',
      id: 'expired',
    },
    {
      name: 'Declined',
      id: 'declined ',
    },
    {
      name: 'NAC',
      id: 'nac',
    },
  ];
};
export const resetPassword = async ({ body }) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT?.resetPassword,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const resetPasswordGetApi = async (token) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT?.resetPasswordGet(token),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const notificationRedirectFormatter = (value, navigate) => {
  let activeStatusObj = {
    // adminNotificationTips: '/customer/tips',
    TRIP_GENERATED_NOTI: `${accessRoute.BOOKING.path}?tab=ongoing`,
    PROVIDER_ARRIVED_NOTI: `${accessRoute.BOOKING.path}?tab=ongoing`,
    PROVIDER_STARTED_JOB_NOTI: `${accessRoute.BOOKING.path}?tab=ongoing`,
  };
  let routeObj = {
    // adminNotificationTips: TipsOfMonth.Tip.path,
    QUOTE_ACCEPTED_BY_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    PROVIDER_ARRIVED_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    PROVIDER_STARTED_JOB_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    JOB_ACCEPTED_BY_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=upcoming`,
    NAC_APPROVE_BY_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=upcoming`,
    PROVIDER_END_TRIP_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    RATING_SUBMIT_BY_PROVIDER_NOTI: reviewRating.RATING_REVIEW.path,
    PROVIDER_SUBMIT_QUOTE_NOTI: `${accessRoute.BOOKING.path}?tab=request`,
    BOOKING_CANCELLED_BY_PATIENT_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    BOOKING_CANCELLED_BY_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    BOOKING_RESCHEDULE_BY_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=upcoming`,
    ADMIN_MANUAL_BOOKING_CUSTOMER_NOTI: `${accessRoute.BOOKING.path}?tab=upcoming`,
    ADMIN_MANUAL_BOOKING_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=upcoming`,
    QUOTE_JOB_EXPIRY_REMINDER_NOTI: `${accessRoute.BOOKING.path}?tab=request`,
    TRIP_DECLINED_BY_PROVIDER_NOTI: homeRoute.HOME.path,
    JOB_DECLINED_BY_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    TRIP_CANCELLED_BY_PROVIDER_NOTI: `${accessRoute.BOOKING.path}?tab=past`,
    PROVIDER_UPDATE_CUSTOMER_PROFILE_NOTI: homeRoute.EDIT_PROFILE.path,
  };

  if (value?.Booking?.bookingStatus === 'active') {
    navigate(activeStatusObj[value?.notificationType]);
  } else if (
    value?.notificationType === 'PROVIDER_REQUEST_PAYMENT_NOTI' ||
    value?.notificationType === 'CUSTOMER_PAY_BY_CARD_NOTI' ||
    value?.notificationType === 'PROVIDER_PAY_BY_CUSTOMER_CARD_NOTI' ||
    value?.notificationType === 'PROVIDER_PAY_BY_CCF_NOTI' ||
    value?.notificationType === 'PROVIDER_PAY_BY_CCF_SPLIT_NOTI' ||
    value?.notificationType === 'PROVIDER_PAY_BY_CUSTOMER_CARD_SPLIT_NOTI'
  ) {
    navigate(
      generatePath(customerRoute.CUSTOMER_PAYMENT.path, {
        id: value?.bookingId,
      }),
      {
        state: value?.bookingId,
      }
    );
  } else {
    navigate(routeObj[value?.notificationType]);
  }
};

export const logoutService = async () => {
  try {
    const payload = {
      ...COMMON_ENDPOINT.logout,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getMarketingShortCodeService = async ({ type }) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT?.marketingShortCode,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const skipLesson = async () => {
  try {
    const payload = {
      ...COMMON_ENDPOINT?.skipLessonTest,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
export const getMentalHealthCount = async ({ queryParams }) => {
  try {
    const payload = {
      ...MENTAL_HEALTH_STATUS?.getMentalHealthStatusCount,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (error) {
    logger(error);
  }
};

export const paymentTypeOptions = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Credit Account',
      id: 'credit_account',
    },
    {
      name: 'Cash',
      id: 'cash',
    },
    {
      name: 'Warranty',
      id: 'warranty',
    },
    {
      name: 'N/A',
      id: 'na',
    },
  ];
};

export const poOptions = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'New Part',
      id: 'new_part',
    },
    {
      name: 'Warranty Part',
      id: 'warranty_part',
    },
    {
      name: 'Tools/Misc',
      id: 'tools_misc',
    },
    {
      name: 'N/A',
      id: 'na',
    },
  ];
};

export const sendOrderEmail = async (id, queryParams) => {
  try {
    const payload = {
      ...COMMON_ENDPOINT.sendOrderInvoiceEmail(id),
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getPlanDurations = () =>{
  return [
    {
      name: 'Monthly',
      id: 'monthly',
    },
    {
      name: 'Yearly',
      id: 'yearly',
    },
  ];
}

export const getMemberShipTypes = () =>{
  return [
    {
      name: 'New',
      id: 'new',
    },
    {
      name: 'Renew',
      id: 'renew',
    },
  ];
}

export const getPurchaseBy = () =>{
  return [
    {
      name: 'Admin',
      id: 'admin',
    },
    {
      name: 'Provider',
      id: 'provider',
    },
    {
      name: 'Customer',
      id: 'customer',
    },
    {
      name: 'Sub Admin',
      id: 'subadmin',
    }
  ];
}
