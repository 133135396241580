import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/Auth/index.service';
import {
  AddMembership,
  EditMembership,
  MembershipList,
  MembershipHistory,
} from '../../views/index.view';

export default function route() {
  return [
    {
      private: true,
      name: 'Membership',
      key: 'membership',
      belongsToSidebar: true,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-cc-alt-fill" />
        </span>
      ),
      moduleKeys: [
        // accessRoute.MEMBERSHIP_ADD.moduleKey,
        // accessRoute.MEMBERSHIP_EDIT.moduleKey,
        accessRoute.MEMBERSHIP.moduleKey,
        accessRoute.MEMBERSHIP_HISTORY.moduleKey,
      ],
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          path: accessRoute.MEMBERSHIP.path,
          name: accessRoute.MEMBERSHIP.name,
          key: accessRoute.MEMBERSHIP.key,
          belongsToSidebar: true,
          // icon: accessRoute.MEMBERSHIP.icon,
          element: <MembershipList />,
          ...accessRoute.MEMBERSHIP,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_HISTORY.path,
          name: accessRoute.MEMBERSHIP_HISTORY.name,
          key: accessRoute.MEMBERSHIP_HISTORY.key,
          belongsToSidebar: true,
          element: <MembershipHistory />,
          ...accessRoute.MEMBERSHIP_HISTORY,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_ADD.path,
          name: 'Add',
          key: accessRoute.MEMBERSHIP_ADD.key,
          belongsToSidebar: false,
          icon: accessRoute.MEMBERSHIP_ADD.icon,
          element: <AddMembership />,
          ...accessRoute.MEMBERSHIP_ADD,
        },
        {
          private: true,
          path: accessRoute.MEMBERSHIP_EDIT.path,
          name: 'Edit',
          key: accessRoute.MEMBERSHIP_EDIT.key,
          belongsToSidebar: false,
          icon: accessRoute.MEMBERSHIP_EDIT.icon,
          element: <EditMembership />,
          ...accessRoute.MEMBERSHIP_EDIT,
        },
      ]
    },
  ];
}

export const accessRoute = {
  MEMBERSHIP: {
    path: '/admin/membership',
    action: getActions('LIST').value,
    key: 'membership.membershipList',
    name: 'Membership Plans',
    moduleKey: 'membership',
    order: 31,
  },
  MEMBERSHIP_HISTORY: {
    path: '/admin/membership/history',
    action: getActions('LIST').value,
    key: 'membership.membershipHistory',
    name: 'Membership History',
    moduleKey: 'membership-history',
    order: 31,
  },
  MEMBERSHIP_ADD: {
    path: '/admin/membership/add',
    action: getActions('CREATE').value,
    key: 'membership.membershipList',
    moduleKey: 'membership',
  },
  MEMBERSHIP_EDIT: {
    path: '/admin/membership/edit/:id',
    action: getActions('EDIT').value,
    key: 'membership.membershipList',
    moduleKey: 'membership',
  },
}
export const getChildrenRoutes = () => {
  return route();
};
