export const text = {
  home: 'Home',
  sno: 'S.No.',
  dashboard: 'Dashboard',
  superAdminSetting: 'Super Admin Setting',
  customerAppSetting: 'Customer App Setting',
  notificationTemplates: 'Notification Templates',
  noRecordFound: 'No Record Found',
  addCity: 'Add City',
  addCountry: 'Add Country',
  cancel: 'Cancel',
  addState: 'Add State',
  submit: 'Submit',
  add: 'Add',
  edit: 'Edit',
  countryName: 'Country Name',
  countryNamePlace: 'Enter country name',
  country: 'Country',
  utility: 'utility',
  localization: 'Localization',
  stateName: 'State Name',
  state: 'State',
  cityName: 'City Name',
  locationDeleteSuccess: 'Location successfully deleted',
  status: 'Status',
  selectStatus: ' Select Status',
  countryCode: 'Country Code',
  countryCodePlace: 'Enter country code ',
  stateCode: 'State Code',
  cityCode: 'City Code',
  countryPhoneCode: 'Country Phone Code',
  countryPhoneCodePlace: 'Enter country phone code',
  unit: 'Unit',
  unitPlace: 'Enter unit',
  banner: 'Banner',
  selectCountry: 'Select country',
  selectCity: 'Select city',
  selectState: 'Select state',
  emergencyNumber: 'Emergency number',
  emergencyNumberPlace: 'Enter emergency number',
  selectAction: 'Select Action',
  reset: 'Reset',
  filter: 'Filter',
  select: 'Select',
  show: 'Show',
  cgst: 'Cgst (IN %)',
  cgstPlace: 'Enter tax',
  serialNo: 'S.No.',
  action: 'Action',
  appText: 'App Text',
  id: 'Id',
  name: 'Name',
  fName: 'First Name',
  lName: 'Last Name',
  mobile: 'Mobile',
  password: 'Password',
  cpassword: 'Confirm Password',
  forgetPasswordHead: 'Forgot Password',
  address: 'Address',
  landmark: 'Landmark',
  city: 'City',
  zipCode: 'Zip Code',
  zipCodePlaceholder: 'Zip code',
  update: 'Update',
  signUp: 'Sign Up',
  signIn: 'Login',
  areYouSure: 'Are you sure?',
  warning: 'warning',
  deleteThisLocations: 'You want to delete this locations',
  delete: 'Delete',
  back: 'Back',
  notes: 'Notes',
  saveNext: 'Save',
  editCity: 'Edit City',
  countryDeletedSuccessfully: 'Country Deleted Successfully',
  cityDeletedSuccessfully: 'City Deleted Successfully',
  stateDeletedSuccessfully: 'State Deleted Successfully',
  areYouSureDeleteCity: 'you want to delete this City',
  areYouSureDeleteCountry: 'you want to delete this Country',
  reYouSureDeleteCity: 'Are you sure to delete this city?',
  areYouSureDeleteBankOption: 'Are you sure to delete this bank option?',
  areYouSureDeleteState: 'you want to delete this State',
  EnglishTitle: 'English Purpose',
  content: 'English Body',
  image: 'Image',
  title: 'Title',
  titlePlaceholder: 'title',
  bannerOrder: 'Banner Order',
  order: 'Order',
  months: 'Months',
  notificationTitle: 'Notification Purpose',
  notificationMessage: 'Notification Message',
  editHomeContent: 'Edit Home Content',
  homePageContent: 'Home Page Content',
  areYouSureDeleteCustomer: 'you want to delete this Customer',
  customerDeletedSuccessfully: 'Customer Deleted Successfully',
  gender: 'Gender',
  profilePicture: 'Profile picture',
  noOfMonth: 'Number of month',
  membership: 'Membership',
  phoneNumber: 'Phone Number',
  phoneNumberPlaceholder: 'Phone number',
  email: 'Email',
  selectMonth: 'Select month',
  selectMembership: 'Select membership',
  rating: 'Rating',
  registrationDate: 'Registration Date',
  fee: 'Fee',
  availability: 'Availability',
  unavailability: 'Unavailability',
  selectDate: 'Select Date',
  saveChanges: 'Save Changes',
  fromDate: 'From Date',
  toDate: 'To Date',
  price: 'Price',
  description: 'Description',
  descriptionPlaceholder: 'description',
  enterYourLocation: 'Enter your location',
  otherMilesRadius: 'Enter Other Miles Radius',
  customerTag: 'Customer Tag',
  jobTag: 'Job Tag',
  customerNotes: 'Customer Notes',
  businessPhoneNumber: 'Business Phone Number',
  enterDescription: 'Enter description',
  enterPrice: 'Enter Price',
  enterName: 'Enter name',
  cost: 'Cost',
  displayOrder: 'Display Order',
  quoteType: 'Quote Type',
  selectDisplayOrder: 'Select Display Order',
  equalTo: 'Equal To',
  and: 'And',
  or: 'Or',
  greaterEqualTo: 'Greater Than Equal To',
  lessEqualTo: 'Less Than Equal To',
  amount: 'Amount',
  icon: 'Icon',
  subCategories: 'Sub Categories',
  location: 'Location',
  selectLocation: 'Select Location',
  category: 'Category',
  categoryDescription: 'Category Description',
  subCategory: 'Subcategory',
  areYouSureDelete: 'Are you sure to delete this?',
  selectSubCategory: 'Select Subcategory',
  subcategoryPrice: 'Subcategory Price',
  enterSubcategoryPrice: 'Enter subcategory price',
  subcategoryDescription: 'Subcategory Description',
  enterSubcategoryDescription: 'Enter subcategory description',
  logo: 'Logo',
  selectLogo: 'Select Logo',
  autoTag: 'Auto Tag',
  selectCustomerTag: 'Select Customer Tag',
  imageDimensionMessage: 'Please upload only png image size of 360px*360px.',
  notification: 'Notification',
  UserName: 'User Name',
  serviceType: 'Service Type',
  providerName: 'Provider Name',
  dateTime: 'Date & Time',
  subject: 'Subject',
  enterSubject: 'Enter subject',
  enterSubject1: 'Enter Subject',
  body: 'Body',
  enterBody1: 'Enter Body',
  enterBody: 'Enter body',
  purpose: 'Purpose ',
  enterPurpose: 'Enter purpose',
  companyName: 'Company Name',
  industryType: 'Industry Type',
  brand: 'Brand',
  directional: 'Directional',
  seriesName: 'Series Name',
  benefits: 'Benefits',
  imageName: 'Image Name',
  enterCompanyName: 'Enter company name',
  enterIndustryType: 'Enter industry type',
  enterBrand: 'Enter brand',
  enterDirectional: 'Enter directional',
  wifi: 'Wifi',
  enterWifi: 'Enter wifi',
  modelStatus: 'Model Status',
  enterModelStatus: 'Enter model status',
  manufacturerType: 'Manufacturer Type',
  enterManufacturerType: 'Enter manufacturer type',
  phase: 'Phase',
  enterPhase: 'Enter phase',
  enterSeriesName: 'Enter series name',
  soldIn: 'Sold in?',
  enterSoldIn: 'Enter sold in?',
  enterRegion: 'Enter region',
  region: 'Region',
  enterBenefits: 'Enter benefits',
  viewDetails: ' View Details',
  services: 'Services',
  bookingLog: 'Booking Log',
  tripBookingNo: 'Booking No',
  date: 'Date',
  log: 'Log',
  logViewer: 'Log Viewer',
  scheduleBookingNo: ' Schedule Booking No',
  jobStatus: 'Job Status',
  provider: 'Provider',
  totalCost: 'Total Cost',
  lastUpdatedSave: 'Last Updated/Save',
  model: 'Model',
  close: 'Close',
  jobNo: 'Job No',
  yes: 'Yes',
  no: 'No',
  graph: 'Graph',
  user: 'User',
  addCustomer: 'Add Customer',
  doHaveAcc: 'Already have an account ?',
  iAgreeWith: 'I agree with',
  resetPassword: 'Reset Password',
  tnc: 'Terms and Conditions',
  login: 'Login',
  LOGIN: 'LOGIN',
  forgetPassword: 'Forgot Password?',
  bookOnline: 'Book Online',
  resetPasswordNote1: 'Submit Email or Mobile Number to reset your password.',
  resetPasswordNote2:
    "Looks like you are a returning customer or we have added your information in the past. Let's reset your password.",
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  enterEnterAddress: 'Enter address line 2',
  suiteNumber: 'Suite Number',
  EnterSuiteNumber: 'Enter suite number',
  save: 'Save',
  enterCost: 'Enter cost',
  startDate: 'Start Date',
  endDate: 'End Date',
  selectStartDate: 'Select start date',
  selectEndDate: 'Select end date',
  myProfile: 'My Profile',
  currentPassword: 'Current Password',
  oldPassword: 'Confirm Password',
  newPassword: 'New Password',
  apply: 'Apply',
  applyPromoCode: 'Applied Promo Code',
  changePassword: 'Change Password',
  upload: 'Upload',
  imageOnlyMessgae: 'Please add either a JPEG file',
  addProvider: 'Add Provider',
  areYouSureDeleteOptionalItem: 'you want to delete this Optional Items',
  imageSizeExceedingMessage: 'File size should not be more than 5 mb',
  optionalItemDeletedSuccessfully: 'Quote optional item deleted successfully',
  siteStatics: 'Site Statistics',
  nca: 'NCA',
  roles: 'Roles',
  companyCode: 'Company Code',
  profileStatus: 'Profile Status',
  planStatus: 'Plan Status',
  paymentReport: 'Payment Report',
  viewEditDocument: 'View/Edit Documents',
  ncaLink: 'NCA Link',
  ncaName: 'NCA Name',
  numberOfficeStaff: 'Number Of Office Staff',
  numberFieldTechniciansInstallers: 'Number Of Field Technicians/Installers',
  editNca: 'Edit NCA',
  goLogReport: 'Go to Log Report',
  ncaDetails: 'NCA Details',
  ncaProfile: 'NCA Profile',
  setUpCompanyProfile: 'Set Up Company Profile',
  setUpCardDetails: 'Setup Card Details',
  setUpBusinessDetails: 'Setup Business Details',
  setUpBankingDetails: 'Setup Banking Details',
  sendEmail: 'Send Email',
  jobId: 'Job ID',
  amountCollected: 'Amount Collected',
  typePayment: 'Type of payment',
  transactionFee: 'Transaction Fee',
  totalPayAmount: 'Total Pay Amount',
  paymentStatus: 'Payment Status',
  paymentSettleStatus: 'Payment Settle Status',
  selectAll: 'Select All',
  paymentTransferStatus: 'Payment Transfer Status',
  document: 'Document',
  editNCA: 'Edit Profile',
  enterFirstName: 'Enter First Name',
  enterLastName: 'Enter Last Name',
  enterPhoneNumber: 'Enter Phone Number',
  enterEmail: 'Enter Email',
  enterAddress: 'Select address',
  EnterlocationRequested:
    'Enter the location where user have requested for job',
  companyAddress: 'Company Address',
  enterCompanyAddress: 'Enter company address',
  enterNumberOfficeStaff: 'Enter number of office staff',
  enterNumberFieldTechniciansInstallers:
    'Enter number of field technicians/installers',
  areaServed: 'Area Served (Enter zip codes or cities) ',
  enterAreaServed: "Seperate each with comma','",
  role: 'Role',
  enterRole: 'Enter Role',
  paymentMode: 'Payment Mode',
  enterPaymentMode: 'Enter Payment Mode',
  cardHolder: 'Card Holder',
  enterCardHolder: 'Enter card holder',
  cardNumber: 'Card Number',
  enterCardNumber: 'Enter card number',
  expMonth: 'Exp. Month',
  selectExpMonth: 'Select month',
  expYear: 'Exp. Year',
  selectExpYear: 'Select year',
  industry: 'Industry',
  ownershipType: 'Ownership Type',
  employerIdentification: 'Employer Identification',
  regBusinessAddress: 'Reg. Business Address',
  doingBusinessAs: 'Doing Business As',
  businessWebsite: 'Business Website',
  representativeOwnerCEO: 'Representative, Owner, CEO',
  dob: 'Date of birth',
  SSNLastFour: 'SSN last four',
  homeAddress: 'Home Address',
  businessTaxID: 'Business Tax ID',
  documentFront: 'Document Front',
  businessVatID: 'Business VAT ID',
  documentBack: 'Document Back',
  bankName: 'Bank Name',
  enterBankName: 'Enter bank name',
  bankAccountHolderName: 'Bank Account Holder Name',
  enterBankAccountHolderName: 'Enter bank account holder name',
  orderNo: 'Order No',
  orderDate: 'Order Date',
  partTitle: 'Part Title',
  partNumber: 'Part Number',
  partCost: 'Part Cost',
  adminOrder: 'Admin Order',
  supplyHouse: 'Supply House',
  removeParts: 'Remove Parts',
  orderParts: 'Order Parts',
  youSure: 'Are you sure',
  orderSelectedPart: 'You want to order selected part ?',
  removeSelectedPart: 'You want to remove selected part ?',
  totalPartsAmount: 'Total Parts Amount',
  customer: 'Customer',
  selectViewEditPlaceholder: 'Select View/Edit',
  locationPlaceholder: 'Select location',
  addExistingCategory: 'Add Existing Category',
  selectLocationPlaceholder: 'Select location',
  selectCategory: 'Select category',
  customerName: 'Customer Name',
  customerNameSearchPlaceHolder: 'customer name',
  purchasedBySearchPlaceHolder : 'purchased by',
  planTypeSearchPlaceHolder : 'plan type',
  customerPhoneNumber: 'Customer Phone Number',
  emailOrMobile: 'Mobile Number / Email',
  addedBy: 'Added By ',
  oldPasswordText: 'Old Password',
  copy: 'Copy',
  youWantToSave: 'you want to save',
  cvv: 'CVV',
  note: 'Note',
  oneTimeFile:
    'Please fill the form carefully because the form cannot be updated after successful submission.',
  enterConfirmPassword: 'Enter confirm password',
  iAgree: 'I agree to the',
  termsService: 'Terms of service',
  privacyPolicy: 'Privacy Policy',
  saveEndNext: 'Save & Next',
  createYourProfile: 'Create your profile',
  alreadyRegistered: 'Already Registered',
  setUpYourCompanyProfile: 'Set Up Your Company Profile  ',
  selectRole: 'Select role',
  selectDuration: 'Select duration',
  creditCardInfo: 'Credit Card Info',
  permissionThisRole: 'Permission for this role',
  skipCompleteLater: 'Skip & Complete Later',
  companyAdmin: 'Company Admin',
  companyAdminSubTitle:
    'Access the Good Guys panel using your email and password.',
  enterYourEmail: 'Enter your email address',
  enterYourPassword: 'Enter your password',
  forgotPassword: 'Forgot Password',
  notHaveAccount: `Don't have an account?`,
  companyAdministratorEmail: 'Company Administrator Email',
  setUpYourBankingDetails: 'Setup Your Banking Details',
  awesome: 'AWESOME',
  awesomeSubtitle:
    'You are well on your way. We are excited you have decided to signup. You will receive a confirmation email that confirms we have received your information.',
  thankYouNot: 'THANK YOU!',
  awesomeSubtitleTwo:
    'In the email you will have a link to click on to schedule a time and finalize your account.',
  Ok: 'Ok',
  search: 'Search',
  pageNotFound: 'Page Not Found',
  WeAreSorry: "We're sorry, the page you requested could not be found.",
  goBack: 'Please go back to the homepage.',
  backToHome: 'Back To Home',
  copyCompanyCode: 'COPY COMPANY CODE',
  copyLink: 'COPY LINK',
  admin: 'Admins',
  editSubAdmin: 'Edit SubAdmin',
  profile: 'Profile',
  pendingTitle: 'Pending Required Procedure',
  completed: 'Completed',
  NoDataFound: 'No data found.',
  deleteRecordTitle: 'Delete Records?',
  goToDashboard: 'Go To Dashboard',
  clickHere: 'Click Here',
  // startDate: 'From date',
  // endDate: 'To date',
  ncaAddressLine: 'Address Line ',
  businessVatId: 'Business VAT ID',
  businessTaxId: 'Business Tax ID',
  dateOfBirth: 'Date of birth',
  doingBusinessAss: 'Doing Business As',
  westfield: 'Westfield',
  businessDetails: 'Business Details',
  accountNumber: 'Account Number',
  bankLocation: 'Bank Location',
  acHolderName: 'A/C Holder Name',
  routingNumber: 'Routing Number',
  emailAddress: 'Email Address',
  bankDetails: 'Bank Details',
  bookNow: 'Book Now',
  enter: 'Enter',
  areYouSureBanner: 'you want to delete this Banner',
  recoverPassword: 'Recover Password',
  send: 'Send',
  enterPassword: 'Enter password',
  phone: 'Phone',
  enterPhone: 'Enter Phone',
  addAction: 'Tags Action',
  saveUploadedData: 'Save uploaded data',
  discardEndBack: 'Discard & Back',
  totalRecords: 'Total Records',
  recordInserted: 'Record Inserted',
  recordUpdated: 'Record Updated',
  ok: 'OK',
  importRecord: 'Import Records',
  goodGuysAdmin: 'Good Guys Admin',
  documentUpdatedSuccess: 'Document added successfully',
  documentUploadedSuccess: 'Document Uploaded successfully',
  deleteCard: 'you want to delete this card',
  subCategoriesCount: 'Sub Categories Count',
  serviceTypeCount: 'Service Type Count',
  companyLogo: 'Company Logo',
  forCompanyLogo: 'For Company Logo',
  identityDocument: 'Identity Document',
  forBusinessTypeIdentityDocument: 'For Business Type Identity Document',
  noDocumentFound: 'No Document Found',
  profileIncompleteINfo: 'Your profile is not completed ',
  toCompleteProfile: 'to complete your profile.',
  requestType: 'Request Type',
  collected: 'COLLECTED',
  uncollected: 'UNCOLLECTED',
  jobStartTime: 'Job Start Time',
  jobEndTime: 'Job End Time',
  ofJobData: '# of jobData',
  view: 'view',
  signUpDate: 'Sign Up Date',
  views: 'View',
  noRatingsReceived: ' No ratings received',
  assign: 'Assign',
  roleAssigned: 'Role Assigned',
  mentalHealthPermission: 'Mental Health Permission',
  assignModules: 'Assign Modules',
  assignModule: 'Assign Module',
  appearance: 'Appearance',
  payment: 'Payment',
  socialMedia: 'Social Media',
  appSettings: 'App Settings',
  installationSettings: 'Installation Settings',
  forgotPasswordMessage: `If you forgot your password, well, then we’ll email you
  instructions to reset your password.`,
  sendResetLink: ' Send Reset Link',
  returnToLogin: 'Return to login',
  questionPersonalInformation: 'Q: Why do we need this personal information?',
  AnswerPersonalInformation: `A: KYC compliance policy requires us to "Know Your
  Customer" when processing payments such as credit card
  transactions, financing, etc.`,
  superAdministrator: 'Super Administrator',
  superAdministratorDetails:
    'Prioritize and solve your tasks in short time cycles',
  companyAdministrator: 'Company Administrator',
  companyAdministratorDetails:
    'Lorem Ipsum is simply dummy text of the printing.',
  superAdminAccess: 'Access the Good Guys panel using your email and password.',
  percentOwnership: 'Percent ownership',
  servicePrice: 'Service Price',
  discount: 'Discount',
  enterdiscount: 'Enter discount',
  post: 'Post',
  level: 'Level',
  yesLogout: 'Yes, Logout!',
  logout: 'Logout',
  weAreServiceProvider: 'We are service provider',
  goodGuysHomeServices: '© 2022 Good Guys Home Services',
  noServiceFound: 'No Service Found',
  noProviderFound: 'No Provider Found ',
  noSlotFound: 'No Slot Found',
  aboutUs: 'About Us',
  contactUs: 'Contact Us',
  termsAndConditions: 'Terms & Conditions',
  next: 'next',
  editQuote: 'Edit Quote',
  noDataFound: 'No Data Found',
  confirm: 'Confirm',
  addCard: 'Add Card',
  serviceTrack: 'Service Track',
  unpaid: 'unpaid',
  contact: 'Contact',
  promoCode: 'Promo Code',
  continue: 'Continue',
  print: 'print',
  emergencyRequestAll: ' Emergency Request to all',
  faqs: `FAQ's`,
  contactUsHeading:
    'Welcome to Good Guys Home Service, the easiest to get around at the tap of a button.',
  whatServiceYouLike: 'What type of service would you like?',
  howItWorks: 'How It Works',
  selectTime: 'Select Time',
  typeOfAppointment: 'What type of appointment?',
  reason: 'Reason',
  cancelJob: 'Cancel Job',
  cancelBooking: 'Cancel Booking',
  skip: 'Skip',
  declined: 'Declined',
  approved: 'Approved',
  discountedPrice: 'Discounted Price',
  noRequestDetails: 'No Request Details',
  at: 'at',
  askTheExpert: 'ask the expert',
  new: 'New',
  viewMore: 'View More',
  notificationText: 'Notification is Empty',
  addCardPay: 'Add Card & pay',
  letsConfirmYourAddress: 'Let’s confirm your address',
  selectConfirmAddressAndNewAddress:
    'Select and confirm your service address or add a new address',
  addLocation: 'Add location',
  manualBooking: 'Manual Booking',
  mobileNumber: 'Mobile Number',
  placeholderMobile: 'Enter your mobile number',
  UpToSeerRating: 'Up to SEER Rating',
  detailsRebates:
    'Rebates are processed and honored by third parties. We cannot guarantee any rebates as their terms can change at any time.',
  qty: 'Qty',
  saveCard: 'Save this card to make your payments easy',
  titlePaymentModal:
    'Please Install the application in order to Pay VIA Cash, Check or Financing',
  schedule: 'Schedule',
  emergencyBooking: 'EMERGENCY BOOKING',
  quantity: 'Qty',
  addAddress: 'Add Address',
  ServiceAddress: 'Service Address',
  prevPaymentPending:
    "Your previous payment is pending so you can't update or cancel your plan.",
  ncaNotSettleAmount:
    'The NCA has not yet added their stripe account so you cannot settle the amount.',
  clickHereToPay: 'Click here to pay',
  pay: 'Pay',
  noQuestionFound: 'No Question Found',
  total: 'Total',
  requiredLessonInfo:
    'There are some required lesson for which test is remaining',
  proceedTest: 'Please click on the Yes to proceed for the test',
  copiedThe: 'Copied the',
  dashboardHeading: 'Hello Admins! Welcome to GGHS.',
  dashboardPara:
    'Together, let’s simplify and shape exceptional home service experiences for our valued customers with our powerful management tool.',
  superAdminLoginHead: 'Super Administrator',
  superAdminLoginPara:
    'Hello, Super Admin! Take charge and transform home services.',
  compAdmLoginHead: 'Company Administrator',
  compAdmLoginPara:
    'Welcome! Start managing your exclusive home services effortlessly.',
  dashboardCopyRight: '© 2023 Good Guys Home Services',
  day: 'day',
  days: 'days',
  subscribe: 'Subscribe',
  approvedTechnician: 'Approved By Technician ',
  tekhneTitle: 'Tékhnē by Good Guys',
  goodGuysTitle: 'Good Guys',
  sendMarketingMessage: 'Send Marketing Message',
  faq: 'FAQ',
  imageDimensionMessage1: 'Please upload only png image size of 800px*550px.',
  imageDimensionMessage2: 'Please upload only png image size of 1925px*460px.',
  imageDimensionMessageT: 'Please upload only png image size of 200px*200px.',
  noItem: 'N/A',
  positive: 'Positive',
  medical: 'Medical',
  struggling: 'Struggling',
  help: 'Help',
  vacation: 'Vacation',
  nameAndTotalFees: 'Name and total fees',
  searchMessage:
    'Paid By, Job Total Amount, Split Payment, Payment Method, Bank Name, and Check or Financing No',
  charactersLimit: 'Type 1 or more characters',
  reportAnIssue: 'Report An Issue',
  general: 'General',

  dragPhoto: 'Drag photos here',
  orIf: 'Or, If you prefer...',
  selectPhotos: 'Select photos from your computer',

  smsNote: "Note :- Please Don't Replace Variable Between # Sign.",
  loadMore: 'Load More',
  loadingMore: 'Loading More',
  myBookRate: 'My Booking/Rating',
  myBookPay: 'My Booking/Paynow',
  applyPromoCode: 'Promo Code  applied!',
  viewBooking: 'View Booking',

  lessonQueAns: 'Question And Answer',
  queAnsWarningOne: 'All questions are compulsory',
  queAnsWarningTwo: 'Please select any option',
  emailTemplate: 'Email Template',
  shortCodes: 'Short Codes',
  approve: 'Approve',
  decline: 'Decline',
  unsubscribeList: 'Unsubscribe User List',
  customerJobTag: 'Customer & Job Tags',
  providerNameTag: 'Provider Name',
  modules: 'Modules',
  messageTemp: 'Message Template',
  goodGuysLLC: 'Good Guys Home Services, LLC',
  appliedOn: 'Applied On',
  discountAmount: 'Discount Amount',
  uptoSeerRate: 'Up to SEER Rating',
  ahri: 'AHRI',
  monthlyPaymentCredit: 'Monthly Payment (with approved credit)',
  rebatesNote:
    'Rebates are processed and honored by third parties. We cannot guarantee any rebates as their terms can change at any time',
  amountDue: 'Amount Due',
  option: 'Options',
  roleAssigned: 'Role Assigned',
  selectAdminType: 'Select Admin Type',
  selectRole: 'Select Role',
  uploadFile: 'Upload File',
  editAvailability: 'Edit Availability',
  companyProfileForm: 'Company Profile Form',
  geoFenceLocation: 'Geo Fence Location',
  serviceCategory: 'Service Category',
  logReport: 'Log Report',
  homePage: 'Home Page',
  editHomePage: ' edit home page',
  mobileEmail: 'Mobile Number/Email',
  storeLogin: 'Log In',
  review: 'Reviews',
  contactTech: 'Contact Tech',
  loadingMore: 'Loading More',
  reviewNoteOne: 'Review module will list all reviews on this page.',
  reviewNoteTwo: 'Administrator can Delete any review.',
  goodGuysLLC: 'Good Guys Home Services, LLC',
  deleteSelectJob: 'Delete selected job(s)',
  deleteSelectedRow: 'Are you sure you want to delete selected row?',
  customerPara:
    'Signing up allows you to schedule appointments, cancel appointments,view past receipts, pay online (contactless payments), view/approve quotes, track your technician on the way to your home, take advantage of any specials, and more.',
  customerHeadThree: 'Service on your way!',
  welcome: 'Welcome to',
  goodGuys: 'Good Guys Home Services!',
  selectStore: 'Select Store',
  manageSlot:
    'The time slot is a combination of both the time interval as well as the start and end date. For Ex. If the time interval is 45 mins, and the start to end time is 12 PM till 3 PM then each slot will be created for 45 mins like 12:00 PM- 12:45 PM, 12:45 PM- 1:30 PM, so on. The same will work for the other time intervals.',

  all: 'All',
  selectSearchCity: 'Search and select  city',
  getEmbedCode: 'Get Embed Code',
  declinedTechnician: 'Declined By Technician ',
  transferred: 'Transferred',
  selectDateLocation: 'Please select date or location',
  flatInUSD: 'Flat In USD',
  percentage: 'In Percentage',
  payNow: 'Pay Now',
  memberPlan: 'Membership Plan',
  membershipType: 'Membership Type',
  monthly: 'Monthly',
  yearly: 'Yearly',
  todaySavings: 'Today Savings',
  wantToCancel: 'Are you sure you want to cancel it ?',
  includes: 'Includes',
  youWant: 'you want to cancel it ?',
  NoPlanFound: 'No Plan Found.',
  canceled: 'Canceled',
  member: 'Member',
  nonMember: 'Non Member',
  nonMemberExpired: 'Non Member Expired',
  memberShipDiscount: 'Membership Discount',
  memberShipSavings: 'Membership Savings',
  customerPaid: 'Customer Paid',
  serviceDiscount: 'Service Discount',
  lineItemDiscount: 'Line Item Discount',
  serviceItemDiscount: 'Service Discount',
  quoteMembershipDiscount: 'Quote Discount',
  productManagement: 'Product Management',
  addProduct: 'Add Product',
  orderManagement: 'Order Management',
  couponManagement: 'Coupon Management',
  addCoupon: 'Add Coupon',
  uploadBulk: 'Bulk Upload',
  editProduct: 'Edit Product',
  shoppingCart: 'Shopping Cart',
  voiceAI:'Voice AI',
  orderInvoice: 'Order Invoice',
  areYouSureDeleteQuoteImage: 'Are You Sure Delete Product Image',
  productName: 'Product Name',
  subTotal:"Sub Total",
  uploadBtnText:"Upload Images",
  quoteStatus:"Quote Status",
  advertisementNoteOne:"Advertisement module will list all interstitial/textual ads on this page.",
  advertisementNoteTwo:"Administrator can Add/Edit/Delete/Active/Inactive any ads. ",
};
