import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { DETECT_OS_ENDPOINT } from '../../apiEndpoints/index.endpoint';

export const detectOSService = async () => {
    try {
        const payload = {
            ...DETECT_OS_ENDPOINT.detectOsUrl,
        };
        const res = await APIRequest(payload);
        return res;
    } catch (err) {
        logger(err);
    }
};
